import { WalletName } from '@helpers/constants';
import { gaEventNameEnum } from '@helpers/enums/gaEventNameEnum';
import * as OpportunityHelper from "@ui/helpers/opportunities/helper";
import { theme } from 'tailwind.config';
export const navItems = [
    {
        id: 0,
        name: 'Dashboard',
        iconLight: 'dashboard-menu-icon-light.svg',
        iconDark: 'dashboard-menu-icon-dark.svg',
        path: '',
    },
    {
        id: 1,
        name: 'My Investments',
        iconLight: 'investment-menu-icon-light.svg',
        iconDark: 'investment-menu-icon-dark.svg',
        path: 'my-investments',
    },
    {
        id: 2,
        name: 'Reinvestments',
        iconLight: 'reinvestment-menu-icon-light.svg',
        iconDark: 'reinvestment-menu-icon-dark.svg',
        path: 'reinvestments',
    },
    {
        id: 3,
        name: WalletName,
        iconLight: 'wallet-icon-light.svg',
        iconDark: 'wallet-icon-dark.svg',
        path: 'ai-safe',
    },
    {
        id: 4,
        name: 'My Referrals',
        iconLight: 'my-referral-menu-icon-light.svg',
        iconDark: 'my-referral-menu-icon-dark.svg',
        path: 'my-referral',
    },
    {
        id: 5,
        name: 'Preferences',
        iconLight: 'wallet-pref-icon-light.svg',
        iconDark: 'wallet-pref-icon-dark.svg',
        path: 'ai-safe/preference',
    },
    {
        id: 6,
        name: 'Income Certificate',
        iconLight: 'paper-light.svg',
        iconDark: 'paper-dark.svg',
        path: 'income-certificate',
    },
];

export const menuItems = [
    {
        name: 'Products',
        showWhenLoggedIn: true,
        showWhenNotLoggedIn: true,
        showOnHomePageOnly: true,
        selectOn: ['/products/[slug]'],
        icon: '/images/header/products-icon.svg',
        gTagMobileLoggedIn: gaEventNameEnum.CLICKED_PRODUCTS_SIDENAV_MOBILE_WEB_NAVIGATION,
        submenu: [
            {
                name: 'Corporate Debt (Unlisted)',
                link: '/products/unlisted-corporate-debt',
                showWhenLoggedIn: true,
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_CD_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Venture Debt',
                link: '/products/venture-debt',
                showWhenLoggedIn: true,
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_VD_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Invoice Discounting',
                link: '/products/invoice-discounting',
                showWhenLoggedIn: true,
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_ID_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Asset Backed Leasing',
                link: '/products/asset-backed-leasing',
                showWhenLoggedIn: true,
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_ABL_SIDENAV_MOBILE_WEB_NAVIGATION
            },
        ],
    },
    {
        name: 'Knowledge Centre',
        showWhenLoggedIn: true,
        showWhenNotLoggedIn: true,
        showOnHomePageOnly: true,
        icon: '/images/header/knowledge-center-icon.svg',
        selectOn: [
            '/blogs',
            '/blogs/[slug]',
            //Hide these for now
            // '/media',
            // '/webinars',
            '/faq',
            '/credit-evaluation-process',
        ],
        gTagMobileLoggedIn: gaEventNameEnum.CLICKED_KNOWLEDGE_CENTER_SIDENAV_MOBILE_WEB_NAVIGATION,
        submenu: [
            {
                name: 'Credit Assessment Process',
                link: '/credit-evaluation-process',
                showWhenLoggedIn: true,
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_CREDIT_EVALUATION_PROCESS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Blogs',
                link: '/blogs',
                showWhenLoggedIn: true,
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_BLOGS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            //Hide these for now
            // {
            //     name: 'Media',
            //     link: '/media',
            //     showWhenLoggedIn: true,
            // },
            {
                name: 'Webinars',
                link: '/webinars',
                showWhenLoggedIn: true,
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_WEBINARS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'FAQs',
                link: '/faq',
                showWhenLoggedIn: true,
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_FAQS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
        ],
    },
    {
        name: 'About Us',
        showWhenLoggedIn: true,
        showWhenNotLoggedIn: true,
        showOnHomePageOnly: true,
        selectOn: ['/about-us', '/team', '/careers', '/contact-us'],
        icon: '/images/header/about-us-icon.svg',
        gTagMobileLoggedIn: gaEventNameEnum.CLICKED_ABOUT_US_SIDENAV_MOBILE_WEB_NAVIGATION,
        submenu: [
            {
                name: 'Our Company',
                link: '/about-us#',
                showWhenLoggedIn: true,
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_OUR_COMPANY_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'The Journey',
                link: '/about-us#milestones',
                showWhenLoggedIn: true,
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_THE_JOURNEY_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Values',
                link: '/about-us#values',
                showWhenLoggedIn: true,
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_VALUES_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Investors',
                link: '/about-us#investors',
                showWhenLoggedIn: true,
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_INVESTORS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            //Hide this for now
            // {
            //     name: 'The Team',
            //     link: '/team',
            //     showWhenLoggedIn: true,
            // },
            {
                name: 'Careers',
                link: '/careers',
                showWhenLoggedIn: false,
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_CAREERS_SIDENAV_MOBILE_WEB_NAVIGATION
            },
            {
                name: 'Contact Us',
                showWhenLoggedIn: false,
                link: '/contact-us',
                id: 'gtm-click-contact-us',
                gTagMobileLoggedIn: gaEventNameEnum.CLICKED_CONTACT_US_SIDENAV_MOBILE_WEB_NAVIGATION
            },
        ],
    },
    {
        name: 'Refer & Earn',
        link: '/referandearn',
        showWhenLoggedIn: false,
        showWhenNotLoggedIn: true,
        showOnHomePageOnly: false,
        selectOn: ['/referandearn'],
    },
    {
        name: 'Become a Partner',
        showWhenLoggedIn: false,
        showWhenNotLoggedIn: true,
        showOnHomePageOnly: true,
        target: '_blank',
        link: `${process.env.NEXT_PUBLIC_APP_PARTNER_URL}`,
        selectOn: [`${process.env.NEXT_PUBLIC_APP_PARTNER_URL}`],
        id: 'gtm-click-partner',
    },
    {
        name: 'Opportunities',
        link: '/opportunities',
        showWhenLoggedIn: true,
        showWhenNotLoggedIn: false,
        showOnHomePageOnly: false,
        id: 'gtm-click-opportunities',
        selectOn: ['/opportunities'],
    },
    {
        name: 'Dashboard',
        link: '/dashboard',
        showWhenLoggedIn: true,
        showWhenNotLoggedIn: false,
        showOnHomePageOnly: false,
        selectOn: [
            '/dashboard',
            '/dashboard/my-investments',
            '/dashboard/my-investments/[investmentId]',
            '/dashboard/my-referral',
            '/dashboard/ai-safe/preference',
            '/dashboard/income-certificate',
        ],
    },
    {
        name: WalletName,
        link: '/dashboard/ai-safe',
        showWhenLoggedIn: true,
        showWhenNotLoggedIn: false,
        showOnHomePageOnly: false,
        selectOn: ['/dashboard/ai-safe'],
        isNew: true,
    },
    {
        name: 'Refer & Earn',
        link: '/refer-and-earn',
        showWhenLoggedIn: true,
        showWhenNotLoggedIn: false,
        showOnHomePageOnly: false,
        selectOn: ['/refer-and-earn'],
        icon: '/images/header/refer-and-earn-icon.svg',
        gTagMobileLoggedIn: gaEventNameEnum.CLICKED_REFER_AND_EARN_SIDENAV_MOBILE_WEB_NAVIGATION
    },
];

export const getAssetClassValue = (name, aggregationByAssetClass) => {
    const value = aggregationByAssetClass
        ?.filter((each) => {
            if (each.assetClass === name) {
                return each.total;
            }
            return 0;
        })
        .map((each) => each.total);
    if (value) {
        return value[0];
    }
    return;
};

export const getPieChartData = (aggregationByAssetClass) => {
    const pieChartData = [
        {
            name: OpportunityHelper.getCorporateDebtOpportunity(),
            label: 'Corporate Debt (unlisted)',
            value: getAssetClassValue(OpportunityHelper.getCorporateDebtOpportunity(), aggregationByAssetClass),
            color: theme?.colors?.pink?.[100],
        },
        {
            name: OpportunityHelper.getAssetBackedLeasingOpportunity(),
            label: 'Asset Backed Leasing',
            value: getAssetClassValue(
                OpportunityHelper.getAssetBackedLeasingOpportunity(),
                aggregationByAssetClass
            ),
            color: theme?.colors?.steelBlue?.[100],
        },
        {
            name: OpportunityHelper.getInvoiceDiscountingOpportunity(),
            label: 'Invoice Discounting',
            value: getAssetClassValue(
                OpportunityHelper.getInvoiceDiscountingOpportunity(),
                aggregationByAssetClass
            ),
            color: theme?.colors?.mauve?.[100],
        },
        {
            name: OpportunityHelper.getVentureDebtOpportunity(),
            label: 'Venture Debt',
            value: getAssetClassValue(OpportunityHelper.getVentureDebtOpportunity(), aggregationByAssetClass),
            color: theme?.colors?.skyBlue?.[100],
        },
        {
            name: OpportunityHelper.getVentureDebtOpportunity(),
            label: 'Treasury Bill',
            value: getAssetClassValue(OpportunityHelper.getVentureDebtOpportunity(), aggregationByAssetClass),
            color: theme?.colors?.skin?.[100],
        },
    ]
        .map((each) => ({
            ...each,
            value: each.value !== undefined ? each.value : 0,
        }))
        .sort((a, b) => b.value - a.value);

    return pieChartData;
};

export const getAssetClassColor = (className) => ({
    [OpportunityHelper.getAssetBackedLeasingOpportunity()]:
        'bg-steelBlue-100',
    [OpportunityHelper.getCorporateDebtOpportunity()]:
        'bg-pink-100',
    [OpportunityHelper.getInvoiceDiscountingOpportunity()]:
        'bg-mauve-100',
    [OpportunityHelper.getVentureDebtOpportunity()]:
        'bg-skyBlue-100',
    [OpportunityHelper.getTreasuryBillOpportunity()]:
        'bg-skin-100',
}[className])
